import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";

export const dealerImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const USImage = ({ data, ...restProps }) => {
  return (
    <Img
      fluid={data.childImageSharp.fluid}
      {...restProps}
      imgStyle={{ objectFit: `contain` }}
    />
  );
}

const UsMoneyExpress = ({ }) => {
  const data = useStaticQuery(graphql`
    query {
      usMoneyExpressLogo: file(relativePath: { eq: "usmoneyexpress.jpg" }) {
        ...squareImage
      }
    }
  `);
  const { usMoneyExpressLogo } = data;
  return (
    <div className="px:4 sm:px-20 pb-4">
      <div className="flex justify-around flex-wrap -mx-2">
        <div className="w-1/2 px-2">
          <div className="h-24 vertical-center">
            <USImage alt="T-mobile" className="h-full block my-0 mx-auto" data={usMoneyExpressLogo} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsMoneyExpress;
