import React from 'react';
import Dealers from '../components/dealers';
import USMoney from '../components/usMoneyExpress';
import { graphql, useStaticQuery } from "gatsby"
import Dot from '../components/Dot';

const Services = () => {
  const { site: { siteMetadata: { services } } } = useStaticQuery(graphql`
    query SiteServicesQuery {
      site {
        siteMetadata {
          services
        }
      }
    }
  `);
  return services.map((service, index) => {
    if (index === 0) {
      return (
        <div className="block sm:pb-2" key={index}>
          <span className="block text-xl sm:text-2xl pb-1 sm:pb-2">
            <Dot />
            <span className="flex-1">
              {service}
            </span>
          </span>
          <USMoney />
        </div>
      )
    } else if (index === 2) {
      return (
        <div className="block sm:pb-2" key={index}>
          <span className="block text-xl sm:text-2xl pb-1 sm:pb-2">
            <Dot />
            <span className="flex-1">
              {service}
            </span>
          </span>
          <Dealers />
        </div>
      )
    }
    const isLast = (index === (services.length - 1));
    return (
      <span className={`flex items-center text-xl sm:text-2xl ${isLast ? `pt-1 sm:pt-2` : `py-1 sm:py-2`}`} key={index}>
        <Dot />
        <span className="flex-1">
          {service}
        </span>
      </span>
    )
  })
};

export default Services;