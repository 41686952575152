import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundLanding from '../components/background-landing';
import SectionsList from "../sections/index";

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        telephone,
      }
    }
  }
`;

function IndexPage({data}) {
  const { site: { siteMetadata: { telephone}}} = data;
  return (
    <Layout withoutContainer>
      <SEO
        keywords={[`telekom`, `chicago`, `mobile`, `ultra mobile`, `polska`]}
        title="Home"
      />
      <BackgroundLanding className="full-width-image">
        <div className="max-w-4xl mx-auto py-4 pl-4 md:py-8 md:pl-8">
          <div className="h-auto">
            <span className="block mb-2 text-5xl sm:text-6xl text-white font-bold uppercase tracking-tight font-logo">
              Telekom
            </span>
            <div className="h-auto pl-4 pr-4 md:pr-0 md:pl-8">
              <span className="text-3xl sm:text-4xl text-base text-white italic">
                <span className="align-middle">Nielimitowane rozmowy do Polski i w USA od</span>
                <span className="text-4xl sm:text-5xl align-middle font-bold ml-4 not-italic">$19</span>
              </span>
            </div>
          </div>
        </div>
      </BackgroundLanding>
      <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 pb-8 md:pb-8 w-full">
        <SectionsList/>
        {/*<section>
          <h2 className="text-4xl font-bold inline-block my-8 py-3">
            Partners
          </h2>
          <Partners/>
        </section>*/}
      </div>
      <section>
        <div className="flex flex-wrap px-4 md:px-10 sm:px-8 py-10 sm:py-16 bg-telekom-yellow-500 text-center">
          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-3/5 pb-10 sm:pb-0 h-auto">
            <span className="text-2xl sm:text-2xl md:text-3xl lg:text-5xl">
              Po więcej informacji dzwoń
              </span>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/2 lg:w-2/5 h-auto">
            <a className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold hover:text-gray-700" href={`tel:${telephone}`}>
              {telephone}
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
