import React from 'react';
import Stores from "./stores";
import Services from "./services";
import Welcome from "./welcome";
import Plans from "./plans";
import Questions from "./questions";
import navItems from './navItems';

const Section = ({ children, id, name }) => {
  return (
    <section className="pt-10 sm:pt-16" id={id}>
      <h2 className="text-3xl sm:text-4xl font-bold inline-block pb-6">
        {name}
      </h2>
      {children}
    </section>
  );
}

const SectionsList = ({ youtubeId, services }) => {
  return navItems.map((navItem, index) => {
    const { id, bodyName } = navItem;
    let body = null;
    if (id === `welcome`) {
      body = (
        <Welcome />
      );
    } else if (id === `services`) {
      body = (
        <div className="px-0 sm:px-4">
          <Services services={services} />
        </div>
      )
    } else if (id === `plans`) {
      body = (
        <div className="px-0 sm:px-4">
          <Plans />
        </div>
      )
    } else if (id === `faq`) {
      body = (
        <div className="px-0 sm:px-4">
          <Questions />
        </div>
      );
    } else if (id === `locations`) {
      body = (
        <div className="flex flex-wrap px-0 sm:px-4 mb-10 sm:mb-20 justify-center">
          <Stores />
        </div>
      );
    }
    return (
      <Section key={index} id={id} name={bodyName}>
        {body}
      </Section>
    )
  })
}

export default SectionsList;
