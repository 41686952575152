import React from 'react';
import { graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image";

export const dealerImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const DealerImage = ({data, ...restProps}) => {
  return (
    <Img
      fluid={data.childImageSharp.fluid}
      {...restProps}
      imgStyle={{ objectFit: `contain` }}
    />
  );
}

const Dealers = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      tmobileLogo: file(relativePath: { eq: "t-mobile-logo.png" }) {
        ...squareImage
      }
      ultraMobileLogo: file(relativePath: { eq: "ultra-mobile.jpg" }) {
        ...squareImage
      }
      simpleMobileLogo: file(relativePath: { eq: "Simplelogo.png" }) {
        ...squareImage
      }
      lycaMobileLogo: file(relativePath: { eq: "lyca-mobile-logo.png" }) {
        ...squareImage
      }
      h2oMobileLogo: file(relativePath: { eq: "h2o-logo.jpg" }) {
        ...squareImage
      }
    }
  `);
  const { tmobileLogo, ultraMobileLogo, simpleMobileLogo, lycaMobileLogo, h2oMobileLogo } = data;
  return (
    <div className="px:4 sm:px-20 pb-4">
      <div className="flex justify-around flex-wrap -mx-2">
        <div className="w-1/3 px-2">
          <div className="h-24 vertical-center">
            <DealerImage alt="T-mobile" className="h-full block my-0 mx-auto" data={tmobileLogo}/>
          </div>
        </div>
        <div className="w-1/3 px-2">
          <div className="h-24 vertical-center">
            <DealerImage alt="Ultra Mobile" className="h-full block my-0 mx-auto" data={ultraMobileLogo} />
          </div>
        </div>
        <div className="w-1/3 px-2">
          <div className="h-24 vertical-center">
            <DealerImage alt="Simple-Mobile" className="h-full block my-0 mx-auto" data={simpleMobileLogo} />
          </div>
        </div>
        <div className="w-1/3 px-2">
          <div className="h-24 vertical-center">
            <DealerImage alt="Lyca Mobile" className="h-full block my-0 mx-auto" data={lycaMobileLogo} />
          </div>
        </div>
        <div className="w-1/3 px-2">
          <div className="h-24 vertical-center">
            <DealerImage alt="H2o Mobile" className="h-full block my-0 mx-auto" data={h2oMobileLogo} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dealers;
