import React from "react";
import { graphql, useStaticQuery} from "gatsby"
import LocationCard from "../components/location-card";

const Stores = () => {
  const { site: { siteMetadata: { stores } } } = useStaticQuery(graphql`
    query SiteStoresQuery {
      site {
        siteMetadata {
          stores {
            name,
            addressLine1,
            addressLine2,
            addressCity,
            addressState,
            frontImage,
            addressZip,
            frontImage,
            hours {
              label,
              value
            }
          }
        }
      }
    }
  `);
  return stores.map((store, index) => {
    return (
      <div className="w-full mb-10 sm:mb-0 sm:w-1/2 lg:w-1/2 xl:w-1/2" key={index}>
        <LocationCard {...store} key={index} />
      </div>
    );
  })
};

export default Stores;
