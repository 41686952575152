import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { FaLocationArrow } from 'react-icons/fa';
import Img from "gatsby-image";
import getDirectionsUrl from '../utils/getGoogleDirectionsUrl';

const Hours = ({hours}) => {
  return hours.map((hour, index) => {
    const {label, value} = hour;
    const isNotLast = (index !== (hours.length -1));
    return (
      <div className="table-row" key={index}>
        <div className="table-cell  text-gray-700 text-base pb-1">
          {label}
        </div>
        <div className={`table-cell text-gray-700 text-base text-center text-base ${isNotLast ? `pb-1` : ``}`}>
          {value}
        </div>
      </div>
    );
  });
};

const LocationImage = ({frontImage, ...restProps}) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);
  const image = data.allImageSharp.edges.find(
    edge => edge.node.fluid.originalName === frontImage
  )
  if (!image) {
    return null
  }
  return (
    <Img
      {...restProps}
      fluid={image.node.fluid}
    />
  );
};


const LocationCard = ({ name, frontImage, addressLine1, addressLine2, addressCity, addressState, addressZip, hours}) => {
  const addressLine = `${addressLine1} ${addressLine2}, ${addressCity} ${addressState}, ${addressZip}`;
  return (
    <div className="max-w-sm rounded border border-black overflow-hidden shadow-lg my-0 mx-auto sm:mx-1 md:mx-5 md:px-0">
      <div className="px-4 py-6 border-b border-black bg-blue-700">
        <div className="font-bold text-white text-center text-3xl uppercase tracking-tight font-logo">
          {name}
        </div>
      </div>
      <LocationImage
        alt={`${name} Front of Store`}
        className="w-full"
        frontImage={frontImage}
      />
      <div className="px-6 py-6">
        <div className="font-bold text-xl mb-1">
          Adres
        </div>
        <div>
          <a className="flex items-center text-gray-700 text-base hover:text-gray-500" href={getDirectionsUrl(addressLine)} rel="noopener noreferrer" target="_blank">
            <address className="flex-auto">
              {`${addressLine1}${addressLine2}`} <br />
              {`${addressCity}, ${addressState}  ${addressZip}`}
            </address>
            <FaLocationArrow className="inline text-center flex-1 text-xl" />
          </a>
        </div>
        {/*<div className="h-40 pb-4">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyA-s9yaBgb7dQBMy2bwU8rboGNHNS1AHtk' }}
            defaultCenter={{ lat: 59.95, lng: 30.33 }}
            defaultZoom={8}
          >
            <Marker
              lat={59.955413}
              lng={30.337844}
              text={name}
            />
          </GoogleMapReact>
        </div>*/}
        <div className="font-bold text-xl mb-1 mt-2">
          Godziny Otwarcia
        </div>
        <div className="table w-full">
          <Hours hours={hours}/>
        </div>
      </div>
    </div>
  )
}

export default LocationCard;
