import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import Dot from '../components/Dot';

const AuthorizationDownload = ({ publicURL, name}) => {
  return (
    <a className="text-sm ml-4 text-blue-700 hover:text-blue-900 hover:underline" download={name} href={publicURL}>
      (Zlecenie Usług Stałych)
    </a>
  );
}

const AnswerList = ({ answers, publicURL, name}) => {
  return answers.map((answer, index) => {
    return (
      <span className="flex items-center pb-1" key={index}>
        <Dot/>
        <span className="flex-1">
          {answer}
          {answer.includes(`automatcznych`) && <AuthorizationDownload name={name} publicURL={publicURL} />}
        </span>
      </span>
    );
  })
}

const Questions = () => {
  const { site: { siteMetadata: { faqQuestions } }, fileName: { publicURL, name} } = useStaticQuery(graphql`
    query SiteFAQQuery {
      site {
        siteMetadata {
          faqQuestions {
            question,
            answers
          }
        }
      },
      fileName: file(relativePath: { eq: "TELEKOMauthorization.pdf" }) {
        publicURL
        name
      }
    }
  `);
  return faqQuestions.map((faq, index) => {
    const {question, answers} = faq;
    const number = (index + 1);
    const isLast = (index === (faqQuestions.length - 1));
    return (
      <div className={`block ${isLast ? `` : `pb-2`}`} key={index}>
        <span className={`block text-lg sm:text-xl py-2`}>
          <span className="pr-2 sm:pr-4 font-bold">{`${number}.`}</span>
          {question}
        </span>
        <span className='block text-base px-4'>
          <AnswerList answers={answers} name={name} publicURL={publicURL}/>
        </span>
      </div>
    );
  })
};

export default Questions;
