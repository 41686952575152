import React, {Fragment} from 'react';
import { graphql, useStaticQuery } from "gatsby"

const ColHeader = ({ children}) => {
  return (
    <div className="flex-1 flex flex-col justify-center px-1 py-4 sm:px-4 sm:py-4 text-center">
      <span className="font-bold text-xl">
        {children}
      </span>
    </div>
  );
};

const Header = () => {
  return (
    <div className="flex h-24 rounded border shadow-lg bg-telekom-yellow-500 text-black border-black">
      <ColHeader>
        Rozmowy + SMS
      </ColHeader>
      <ColHeader>
        Internet
      </ColHeader>
      <ColHeader>
        Rozmowy za granicę
      </ColHeader>
    </div>
  )
}

const ColRow = ({children, className, textClass}) => {
  return (
    <div className={`flex-1 flex flex-col justify-center px-1 py-4 sm:px-4 sm:py-4 text-center ${className || ``}`}>
      <span className={`${textClass || `text-base`}`}>
        {children}
      </span>
    </div>
  );
}

const PriceBadge = ({price}) => {
  return (
    <div
      className="text-white left-1 sm:-left-0.5 border-2 border-black text-lg sm:text-2xl bg-blue-700 group-hover:text-black group-hover:bg-telekom-yellow-500 h-12 w-12 sm:h-16 sm:w-16 flex items-center justify-center rounded-full absolute"
      style={{ top: `50%`, transform: `translate(-50%, -50%)`}}
  >
      <span className="text-base">$</span>
      <span className="font-bold">{price}</span>
    </div>
  )
};

const SMSText = ({text}) => {
  if (text.toLowerCase() === 'nielimitowane') {
    return (
      <Fragment>
        <span className="hidden sm:inline-block">
          {text}
        </span>
        <span className="sm:hidden">
          bez Limit
        </span>
      </Fragment>
    )
  }
  return text;
}

const PlanRow = ({ price, talk, sms, internet, international, isLast}) => {
  return (
    <div className={`group h-24 flex cursor-pointer hover:bg-gray-200 hover:border-blue-700 border-t border-black relative ${isLast ? `border-b-2` : `border-b`}`}>
      <PriceBadge price={price} />
      <ColRow className="border-black border-r-2 pl-6 pr-1 sm:pl-4 sm:pr-4">
        <SMSText text={sms}/>
      </ColRow>
      <ColRow className="border-black border-r-2">
        {internet}
      </ColRow>
      <ColRow textClass="text-sm sm:text-base">
        {international}
      </ColRow>
    </div>
  );
}

const PlansList = ({plans}) => {
  return plans.map((plan, index) => {
    const isLast = (index === (plans.length - 1));
    return (
      <PlanRow {...plan} isLast={isLast} key={index}/>
    );
  })
}

const TextSmall = ({children, className}) => {
  return (
    <span className={`text-xs italic text-gray-600 ${className}`}>
      <span className="mr-1">*</span>
      {children}
    </span>
  );
}

const HelperText = ({text}) => {
  return (
    <div className="pl-0 pr-0 sm:pl-5 sm:pr-5 py-5">
      <TextSmall className="text-sm">
        {text}
      </TextSmall>
    </div>
  );
};

const CountryList = ({countries}) => {
  return countries.sort((a, b) => a.name.localeCompare(b.name)).map((country, index) => {
    const {name, onlyCell} = country;
    return (
      <div className="w-1/4 sm:w-1/6" key={index}>
        <span className="text-sm">
        {name}
        {onlyCell && <span className="ml-1">*</span>}
        </span>
      </div>
    );
  });
}

const Countries = ({countries, helperText}) => {
  return (
    <Fragment>
      <div>
        <span className="font-bold text-xl">
          Kraje 1:
        </span>
      </div>
      <div className="flex flex-wrap px-0 sm:px-5 py-1">
        <CountryList countries={countries}/>
      </div>
      <div className="py-2 px-0 sm:px-5">
        <TextSmall>
          Tylko na tel. stacjonarne
        </TextSmall>
      </div>
    </Fragment>
  );
}

const Plans = () => {
  const { site: { siteMetadata: { plans, plansHelperText, plansCountryHelperText, plansCountries } } } = useStaticQuery(graphql`
    query SitePlansQuery {
      site {
        siteMetadata {
          plans {
            price,
            talk,
            sms,
            internet,
            international
          },
          plansCountries {
            onlyCell,
            name
          },
          plansCountryHelperText,
          plansHelperText
        }
      }
    }
  `);
  return (
    <Fragment>
      <Header />
      <PlansList plans={plans}/>
      <HelperText text={plansHelperText}/>
      <Countries
        countries={plansCountries}
        helperText={plansCountryHelperText}
      />
    </Fragment>
  );
};

export default Plans;