import React from 'react';

const Dot = ({className}) => {
  return (
    <span className={`pr-2 sm:pr-4 font-bold ${className}`}>
      &#8729;
    </span>
  );
}

export default Dot;