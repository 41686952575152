import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

const BackgroundLanding = ({ className, style, children}) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: {eq: "telekom-landing-blurred.png"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const imageData = data.desktop.childImageSharp.fluid
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.9))`,
    imageData
  ];
  return (
    <BackgroundImage
      className={className}
      fluid={backgroundFluidImageStack}
      style={style}
      Tag="section"
    >
      {children}
    </BackgroundImage>
  );
};

export default BackgroundLanding;
