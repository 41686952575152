import React, {Fragment} from 'react';
import YouTube from 'react-youtube';
import { useStaticQuery, graphql } from "gatsby"

// https://developers.google.com/youtube/player_parameters
const opts = {
  height: `390`,
  width: `640`,
  playerVars: {
    autoplay: 0
  }
};

const Welcome = () => {
  const { site: { siteMetadata: { youtubeId } } } = useStaticQuery(graphql`
    query SiteYouTubeIdQuery {
      site {
        siteMetadata {
          youtubeId
        }
      }
    }
  `);
  return (
    <Fragment>
      <span className="block px-4 text-gray-700 text-lg">
        Witamy na naszej stronie internetowej.
        Tutaj zdobedziesz wszystkie informacje i nowosci o naszej Firmie.
        W miesiacach marzec, kwiecien, maj 2015 przygotowalismy dla Panstwa wiele rabatow na rozne modele telefonow komorkowych, zarowno “smart phones” jak i tych, ktore uwielbiaja nasi klienci czyli prostych w obsludze z duzymi cyframi i wyswietlaczem. Serdecznie zapraszamy do naszego sklepu 7 dni w tygodniu jestesmy do Panstwa dyspozycji, odpowiemy na kazde pytanie i dobierzemy plan na rozmowy telefoniczne zgodnie z Panstwa potrzebami. DO ZOBACZENIA W TELEKOMIE!!!!
      </span>
      <div className="px-4 sm:px-10 md:px-16 py-10">
        <div className="videoWrapper">
          <YouTube
            className="absolute top-0 left-0 w-full h-full"
            opts={opts}
            videoId={youtubeId}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Welcome;
